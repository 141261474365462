<script>
/**
 * @Author: Andrea Dittler | webspezi.com
 * @Date:   2023-04-03 19:58:45
 * @Last Modified by:   Andrea Dittler | webspezi.com
 * @Last Modified time: 2023-04-04 14:39:23
 */

</script>

<template>
  <div>
    <h1 class="special-heading light-special mb-5">Richtung verloren?</h1>
    <div role="" class="row justify-content-center align-items-start  wsFlexTyp-standard" id="Kombiblk_block1__6">
      <div class=" col-12 col-sm-6 col-lg-7 ">
        <div class="text-white  text-center">

        </div>


      </div>
    </div>
    <div role="" class="row justify-content-center align-items-start  wsFlexTyp-standard">
      <div class="wsFlex col-12 col-sm-8 col-md-6 has-wsBlocksInfoBox KombiEbene1 ">

        <div class="milchglas position-relative" id="milchglas" ref="milchglas">
          <div class="innen" style="cursor: pointer;">
            <div class="iconbox iconbox-light iconbox-center">
              <div class="iconbox-wrapper">
                <div class="iconbox-icon mb-5"><i :class="`${os.icon} fa-6x fab text-white`"></i></div>
                <div class="iconbox-content text-start">
                  <p v-if="ipAddress"><b>IPv4:</b> {{ ipAddress }}</p>
                  <p v-if="ip6Address"><b>IPv6:</b> {{ ip6Address }}</p>
                  <p><b>Betriebssystem:</b> {{ os.name }}</p>
                  <p><b>Browser:</b> {{ os.browserName }} {{ os.browserVersion }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>





    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, onBeforeUnmount, computed, onMounted, onUpdated, watch } from 'vue'
import { applyMilchglasEffect } from './shared.js'
const ipAddress = ref('');
const ip6Address = ref('');
const os = computed(() => {
  const userAgent = window.navigator.userAgent;
  let os = null;
  if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
    os = { name: 'MacOS', icon: 'fa-apple', link: 'https://get.teamviewer.com/webspezi' };
  } else if (/Windows|Win16|Win32|Win64|WinCE/.test(userAgent)) {
    os = { name: 'Windows', icon: 'fa-windows', link: 'https://get.teamviewer.com/webspezi' };
  } else if (/Linux/.test(userAgent)) {
    os = { name: 'Linux', icon: 'fa-linux', link: 'https://get.teamviewer.com/webspezi' };
  } else if (/iPhone|iPod|iPad/.test(userAgent)) {
    os = { name: 'iOS', icon: 'fa-apple', link: 'https://get.teamviewer.com/webspezi' };
  } else if (/Android/.test(userAgent)) {
    os = { name: 'Android', icon: 'fa-android', link: 'https://play.google.com/store/apps/details?id=com.teamviewer.quicksupport.market' };
  } else {
    os = { name: 'Unbekannt', icon: 'fa-question', link: 'https://get.teamviewer.com/webspezi' };
  }
  if (/MSIE|Trident/.test(userAgent)) {
    os.browserName = 'Internet Explorer';
    const match = userAgent.match(/(?:MSIE |rv:)(\d+(\.\d+)?)/);
    os.browserVersion = match ? match[1] : '';
  } else if (/Edge|Edg\//.test(userAgent)) {
    os.browserName = 'Microsoft Edge';
    const match = userAgent.match(/(?:Edge|Edg\/)(\d+(\.\d+)?)/);
    os.browserVersion = match ? match[1] : '';
  } else if (/Chrome/.test(userAgent)) {
    os.browserName = 'Google Chrome';
    const match = userAgent.match(/(?:Chrome|CriOS)\/(\d+(\.\d+)?)/);
    os.browserVersion = match ? match[1] : '';
  } else if (/Firefox/.test(userAgent)) {
    os.browserName = 'Mozilla Firefox';
    const match = userAgent.match(/(?:Firefox|Fxios)\/(\d+(\.\d+)?)/);
    os.browserVersion = match ? match[1] : '';
  } else if (/Safari/.test(userAgent)) {
    os.browserName = 'Safari';
    const match = userAgent.match(/(?:Safari|Version)\/(\d+(\.\d+)?)/);
    os.browserVersion = match ? match[1] : '';
  } else {
    os.browserName = 'Unbekannt';
    os.browserVersion = '';
  }
  return os;
})
const getIP = async () => {
  try {
    const response = await fetch('https://ipspezi.de/ipv4.php?asJson=1');
    const data = await response.json();
    ipAddress.value = data.ip;
  } catch (error) {
    ipAddress.value = 'IP-Fehler';
  }
  try {
    const response = await fetch('https://ipspezi.com/ipv6.php?asJson=1');
    const data = await response.json();
    ip6Address.value = data.ip;
  } catch (error) {
    ip6Address.value = 'IP-Fehler';
  }
}
onBeforeMount(() => {
  window.addEventListener('resize', applyMilchglasEffect);
  window.addEventListener('scroll', applyMilchglasEffect);
  //applyMilchglasEffect();
});
onUpdated(() => {
  applyMilchglasEffect();
});
onMounted(() => {
  applyMilchglasEffect();
  getIP()
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', applyMilchglasEffect);
  window.removeEventListener('scroll', applyMilchglasEffect);
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
div {
  font-size: clamp(16px, 1.3vw, 25px);
  font-weight: 400;
}
</style>
