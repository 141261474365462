/**
 * @Author: Andrea Dittler | webspezi.com
 * @Date:   2023-04-04 11:13:50
 * @Last Modified by:   Andrea Dittler | webspezi.com
 * @Last Modified time: 2023-04-04 12:05:16
 */
export const applyMilchglasEffect = () => {

        const quelle = document.querySelector('.bg');
        const qbild = document.querySelector('.bg img');
        const wg = document.querySelector('#wasserglas');
        const mg = document.querySelector('#milchglas');

        const { width: qw, height: qh, left: ql, top: qt } = qbild.getBoundingClientRect();
        const { width: zw, height: zh, left: zl, top: zt } = mg.getBoundingClientRect();

        wg.style.background = `url(${quelle.dataset.srcalt})`;
        wg.style.width = `${zw}px`;
        wg.style.height = `${zh}px`;
        wg.style.top = `${zt}px`;
        wg.style.left = `${zl}px`;
        wg.style.backgroundPosition = `${parseInt(qbild.clientWidth - zl + ql)}px ${parseInt(qbild.clientHeight - zt + qt)}px`;
        wg.style.backgroundSize = `${qbild.clientWidth}px ${qbild.clientHeight}px`;
};