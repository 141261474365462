/**
 * @Author: Andrea Dittler | webspezi.com
 * @Date:   2023-04-04 11:32:56
 * @Last Modified by:   Andrea Dittler | webspezi.com
 * @Last Modified time: 2023-04-04 12:19:08
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
createApp(App).use(router).mount('#app')
